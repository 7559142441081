<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header border-0 justify-content-between">
            <div class="card-title">{{ title }}</div>
          </div>
          <div class="card-body">
            <el-skeleton :loading="isLoading" :rows="6" animated />
            <b-alert v-if="$route.params.action === 'edit' && form.review_status === 'rejected'" show variant="danger">
              <h4 class="alert-heading">This promo has been rejected!</h4>
              <p style="word-wrap: break-word; white-space: pre-wrap;">{{ form.reject_reason }}</p>
            </b-alert>
            <b-alert v-if="$route.params.action === 'review' && last_reject_reason" show variant="danger">
              <h4 class="alert-heading">Last Rejected Reason</h4>
              <p style="word-wrap: break-word; white-space: pre-wrap;">{{ last_reject_reason }}</p>
            </b-alert>
            <b-form v-if="!isLoading" @submit.prevent="createNewPromo" @reset="closeModalCreate">
              <b-form-group label="Promo Name">
                <b-input
                  v-model="v$.form.name.$model"
                  placeholder="Input promo name"
                  type="text"
                  />
                <b-form-invalid-feedback :state="!v$.form.name.$invalid" v-if="v$.form.name.$errors[0]">
                  <span> {{ v$.form.name.$errors[0].$message }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Promo Code">
                <b-input
                  v-model="v$.form.voucher_code.$model"
                  oninput="this.value = this.value.toUpperCase()"
                  placeholder="Input voucher code"
                  type="text"
                  />
                <b-form-invalid-feedback :state="!v$.form.voucher_code.$invalid" v-if="v$.form.voucher_code.$errors[0]">
                  <span> {{ v$.form.voucher_code.$errors[0].$message }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Description">
                <quill-editor v-model="form.promo_description"
                  ref="promoDescriptionQuill"
                  :options="editorOption"
                  >
                </quill-editor>
              </b-form-group>
              <b-form-group label="Banner">
                <b-form-file
                  v-model="selected_file.promo_banner"
                  :state="Boolean(selected_file.promo_banner)"
                  class="mt-2 border-1 p-2"
                  accept=".png, .jpg, .jpeg"
                  @input="onChangeFile($event, 'promo_banner')"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."

                ></b-form-file>
                <img ref="current_promo_banner" :src="form.current_promo_banner"/>
              </b-form-group>
              <b-form-group label="Logo">
                <b-form-file
                  v-model="selected_file.promo_image_url"
                  :state="Boolean(selected_file.promo_image_url)"
                  class="mt-2 border-1 p-2"
                  accept=".png, .jpg, .jpeg"
                  @input="onChangeFile($event, 'promo_image_url')"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."

                ></b-form-file>
                <img ref="current_promo_image_url" :src="form.current_promo_image_url"/>
              </b-form-group>
              <b-form-group label="Banner Homepage">
                <b-form-file
                  v-model="selected_file.promo_image_homepage"
                  :state="Boolean(selected_file.promo_image_homepage)"
                  class="mt-2 border-1 p-2"
                  accept=".png, .jpg, .jpeg"
                  @input="onChangeFile($event, 'promo_image_homepage')"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."

                ></b-form-file>
                <img ref="current_promo_image_homepage" :src="form.current_promo_image_homepage"/>
              </b-form-group>
              <b-form-group label="Promo Type">
                <el-select
                  class="w-full"
                  v-model="v$.form.type.$model"
                  placeholder="Select promo type"
                  >
                  <el-option v-for="(item, index) in promoTypeOptions" :value="item.value" :key="index" :label="item.text"/>
                </el-select>
                <b-form-invalid-feedback :state="!v$.form.type.$invalid" v-if="v$.form.type.$errors[0]">
                  <span> {{ v$.form.type.$errors[0].$message }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Product Type">
                <el-select
                  multiple
                  class="w-full"
                  v-model="form.promo_product_type"
                  placeholder="Select product type"
                  >
                  <el-option v-for="(item, index) in productTypeOptions" :value="item.value" :key="index" :label="item.label"/>
                </el-select>
                <small class="text-warning"><i>*Leave blank if this promo apply to all product</i></small>
              </b-form-group>
              <!-- <b-form-group label="Specific SKU" v-if="form.promo_product_type.includes('voucher')">
                  <el-select
                    filterable
                    :filter-method="handlerSearchVoucherSku"
                    :loading="loading.voucher_sku"
                    loading-text="Searching data..."
                    v-model="v$.form.sku_vendor_id.$model" placeholder="Select Voucher" class="mr-2 w-full">
                    <el-option
                      v-for="item in uv_voucher"
                      :key="item.id"
                      :label="item.sku_name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                  <small class="text-warning"><i>*Choose specific SKU</i></small>
                  <b-form-invalid-feedback :state="!v$.form.sku_vendor_id.$invalid" v-if="v$.form.sku_vendor_id.$errors[0]">
                    <span> {{ v$.form.sku_vendor_id.$errors[0].$message }}</span>
                  </b-form-invalid-feedback>
              </b-form-group> -->
              <b-form-group label="Specific SKU" v-if="form.promo_product_type.includes('voucher')">
                <el-select @change="handlerVoucherChange" multiple :loading="isLoadingVoucher" v-model="v$.form.voucher_id.$model" placeholder="Select or search voucher" class="w-full" filterable remote :remote-method="searchVoucher">
                <el-option value="" label="All">All</el-option>
                <el-option
                  v-for="item in list_voucher"
                  :key="item.voucher_id"
                  :label="item.voucher_name"
                  :value="item.voucher_id">
                </el-option>
                </el-select>
                <small class="text-warning"><i>*Leave blank if this Specific SKU apply to all voucher</i></small>
              </b-form-group>
              <b-form-group label="Device Platform">
                <el-select
                  multiple
                  class="w-full"
                  v-model="form.promo_platform"
                  placeholder="Select product type"
                  >
                  <el-option value="all" label="All"/>
                  <el-option v-for="(item, index) in deviceTypeOptions" :value="item.value" :key="index" :label="item.text"/>
                </el-select>
              </b-form-group>
              <b-form-group label="Set as Exclusive Promo">
                <el-switch
                  :active-value="true"
                  :inactive-value="false"
                  active-text="Yes"
                  inactive-text="No"
                  v-model="form.is_exclusive_promo"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  >
                </el-switch>
              </b-form-group>
              <b-form-group label="Set as MyBenefit Promo">
                <el-switch
                  :active-value="true"
                  :inactive-value="false"
                  active-text="Yes"
                  inactive-text="No"
                  v-model="form.is_mybenefit"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  >
                </el-switch>
              </b-form-group>
              <b-form-group label="Limit promo by company" v-if="form.is_mybenefit">
                <el-select
                  multiple class="w-full" v-model="form.promo_company_id"
                  filterable
                  :filter-method="handlerSearchCompany"
                  placeholder="Select company"
                  >
                  <el-option v-for="(item) in company_list" :value="item.company_id" :key="item.company_id" :label="item.company_name"/>
                </el-select>
                <br/><small><i>note: Just leave it if this promo apply to all private user</i></small>
              </b-form-group>
              <b-form-group label="Promo Start Date">
                <el-date-picker
                  :picker-options="datePickerOptions"
                  class="w-full"
                  v-model="v$.form.start_date.$model"
                  type="datetime"
                  placeholder="Pick start date"
                  />
                  <!-- {{ v$.form.start_date }} -->
                <b-form-invalid-feedback :state="!v$.form.start_date.$invalid" v-if="v$.form.start_date.$error">
                  <span v-if="v$.form.start_date.$errors[0].$validator !== 'minValue'"> {{ v$.form.start_date.$errors[0].$message }}</span>
                  <span v-else> Value must below {{ moment(v$.form.end_date.$model).format('YYYY-MM-DD') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Promo End Date">
                <el-date-picker
                  :picker-options="datePickerOptions"
                  class="w-full"
                  v-model="v$.form.end_date.$model"
                  type="datetime"
                  placeholder="Pick end date"
                  />
                <b-form-invalid-feedback :state="!v$.form.end_date.$invalid" v-if="v$.form.end_date.$error">
                  <!-- <span v-if="v$.form.end_date.$errors[0].$validator !== 'minValue'"> {{ v$.form.end_date.$errors[0].$message }}</span>
                  <span v-else> Value must below {{ moment(v$.form.start_date.$model).format('YYYY-MM-DD') }}</span> -->
                  <span v-if="v$.form.end_date.$errors[0].$validator !== 'validateEndAfterStart'">{{ v$.form.end_date.$errors[0].$message }}</span>
                  <!-- <span v-else>End date must be on or after start date</span> -->
                  <span v-else> The end date must be later than the start date</span> 
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Promo Limitation by Registration Date">
                <el-switch
                  :active-value="true"
                  :inactive-value="false"
                  active-text="Yes"
                  inactive-text="No"
                  v-model="form.limitation_by_register_date"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  >
                </el-switch>
              </b-form-group>
              <b-form-group v-if="form.limitation_by_register_date" label="Registration Start Date">
                <el-date-picker
                  class="w-full"
                  v-model="v$.form.register_start_date.$model"
                  type="date"
                  placeholder="Pick start registration date"
                  />
                  <!-- {{ v$.form.start_date }} -->
                <b-form-invalid-feedback :state="!v$.form.register_start_date.$invalid" v-if="v$.form.register_start_date.$error">
                  <span v-if="v$.form.register_start_date.$errors[0].$validator !== 'minValue'"> {{ v$.form.register_start_date.$errors[0].$message }}</span>
                  <span v-else> Value must below {{ moment(v$.form.register_end_date.$model).format('YYYY-MM-DD') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group v-if="form.limitation_by_register_date" label="Registration End Date">
                <el-date-picker
                  class="w-full"
                  v-model="v$.form.register_end_date.$model"
                  type="date"
                  placeholder="Pick end registration date"
                  />
                <b-form-invalid-feedback :state="!v$.form.register_end_date.$invalid" v-if="v$.form.register_end_date.$error">
                  <span v-if="v$.form.register_end_date.$errors[0].$validator !== 'minValue'"> {{ v$.form.register_end_date.$errors[0].$message }}</span>
                  <span v-else> Value must below {{ moment(v$.form.register_start_date.$model).format('YYYY-MM-DD') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Promo will become Cashback">
                <el-switch
                  :active-value="true"
                  :inactive-value="false"
                  active-text="Yes"
                  inactive-text="No"
                  v-model="form.is_cashback"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  >
                </el-switch>
              </b-form-group>
              <b-form-group label="Discount Type">
                <el-select
                  class="w-full"
                  v-model="v$.form.discount_type.$model"
                  placeholder="Select discount type"
                  >
                  <el-option v-for="(item, index) in discountTypeOptions" :value="item.value" :key="index" :label="item.text"/>
                </el-select>
                <b-form-invalid-feedback :state="!v$.form.discount_type.$invalid" v-if="v$.form.discount_type.$errors[0]">
                  <span> {{ v$.form.discount_type.$errors[0].$message }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Payment Method">
                <el-select
                  class="w-full"
                  v-model="v$.form.promo_payment_method.$model"
                  multiple
                  @change="changeHandlerPaymentMethod"
                  placeholder="Select allowed payment method"
                  >
                  <el-option value="all" label="All"/>
                  <el-option v-for="(item, index) in payment_method_list" :value="item.id" :key="index" :label="item.payment_name">
                    <div class="flex" style="max-height: 70px">
                      <img :src="item.payment_icon" style="max-width: 70px; object-fit: cover;"/>
                      <span class="ml-2">{{ item.payment_name }}</span>
                    </div>
                  </el-option>
                </el-select>
                <b-form-invalid-feedback :state="!v$.form.promo_payment_method.$invalid" v-if="v$.form.promo_payment_method.$errors[0]">
                  <span> {{ v$.form.promo_payment_method.$errors[0].$message }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Minimum Amount Transaction">
                <b-input
                  v-model="v$.form.minimum_amount_transaction.$model"
                  placeholder="Input minimum amount per transaction"
                  type="number"
                  />
                <b-form-invalid-feedback :state="!v$.form.minimum_amount_transaction.$invalid" v-if="v$.form.minimum_amount_transaction.$errors[0]">
                  <span> {{ v$.form.minimum_amount_transaction.$errors[0].$message }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Discount Amount">
                <b-input
                  v-model="v$.form.discount_amount.$model"
                  placeholder="Input discount amount"
                  type="number"
                  />
                <b-form-invalid-feedback :state="!v$.form.discount_amount.$invalid" v-if="v$.form.discount_amount.$errors[0]">
                  <span> {{ v$.form.discount_amount.$errors[0].$message }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Max. Discount Amount">
                <b-input
                  v-model="v$.form.discount_amount_max.$model"
                  placeholder="Input maximal discount amount per transaction"
                  type="number"
                  />
                <b-form-invalid-feedback :state="!v$.form.discount_amount_max.$invalid" v-if="v$.form.discount_amount_max.$errors[0]">
                  <span> {{ v$.form.discount_amount_max.$errors[0].$message }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Limit Usage per user">
                <b-input
                  v-model="v$.form.max_use.$model"
                  placeholder="Input limitation for promo per user per periode"
                  type="number"
                  />
                <b-form-invalid-feedback :state="!v$.form.max_use.$invalid" v-if="v$.form.max_use.$errors[0]">
                  <span> {{ v$.form.max_use.$errors[0].$message }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Daily Limit Usage per user">
                <b-input
                  v-model="form.promo_quote_day"
                  placeholder="Input limitation for promo usage per user per day"
                  type="number"
                  />
              </b-form-group>
              <b-form-group label="Limit Quota">
                <b-input
                  v-model="form.promo_quote"
                  placeholder="Input limitation for promo usage per periode"
                  type="number"
                  />
              </b-form-group>
              <b-form-group label="Limit promo by selected user">
                <el-button @click="chooseUserHandler(form.promo_company_id)" size="small" icon="el-icon-user">Choose User</el-button>
                <br/><small><i>note: Just leave it if this promo apply to all user</i></small>
              </b-form-group>
              <b-form-group>
                <el-checkbox v-model="form.is_first_transaction" :true-label="true" :false-label="false" label="Apply Promo for first transaction" size="large" />
              </b-form-group>
              <b-form-group label="Reject Reason" v-if="$route.params.action == 'review'">
                <b-textarea
                  rows="6"
                  v-model="v$.form.reject_reason.$model"
                  placeholder="Input reason why you reject this promotion"
                  type="textarea"
                  />
                <b-form-invalid-feedback :state="!v$.form.reject_reason.$invalid" v-if="v$.form.reject_reason.$errors[0]">
                  <span> {{ v$.form.reject_reason.$errors[0].$message }}</span>
                </b-form-invalid-feedback>
                <small class="text-warning"><i>*Not required if you want to approve this promotion</i></small>
              </b-form-group>
              <div class="d-flex flex-row justify-content-between">
                <div class="float-left" v-if="currentRole.can_param1 && $route.params.action == 'review' && form.review_status === 'in_review'">
                  <el-button  @click="confirmApproveReject('approve')" size="small" type="success" class="mr-2">Approve</el-button>
                  <el-button  @click="confirmApproveReject('reject')" size="small" type="danger" class="mr-2">Reject</el-button>
                </div>
                <div class="float-right" v-if="currentRole.can_edit">
                  <!-- <el-button  :disabled="!isPaymentMethodSelected" @click="confirmCreatePromo" :loading="loading.create_promo" class="mr-2 ml-2" size="small" type="primary">Submit Promo</el-button> -->
                  <el-button  :disabled="v$.form.end_date.$invalid || !isPaymentMethodSelected" @click="confirmCreatePromo" :loading="loading.create_promo" class="mr-2 ml-2" size="small" type="primary">Submit Promo</el-button>
                  <el-button @click="closeModalCreatePromo" size="small" class="mr-2">Cancel</el-button>
                </div>
                <!-- <b-button class="mr-2" size="sm" @click="closeModalGiveVoucher" type="reset" variant="secondary">Cancel</b-button> -->
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-lg-4" style="max-height: 800px; overflow-y:scroll">
        <div class="card">
          <div class="card-header border-0 justify-content-between">
            <div class="card-title">History</div>
          </div>
          <div class="card-body">
            <el-skeleton :loading="isLoadingHistory" :rows="6" animated />
            <el-timeline v-if="total_rows > 0 && !isLoadingHistory">
              <el-timeline-item v-for="item in list" :timestamp="item.created_at_simple"
                placement="top"
                :color="item.dot_color"
                :type="item.dot_color"
                :key="item.id">
                <el-card>
                  <h4 :style="{ color: item.dot_color }">{{ item.action_str }}</h4>
                  <p>{{ item.user.name }} {{ item.action_str.toLowerCase() }} at {{ item.created_at_str }}</p>
                </el-card>
              </el-timeline-item>
            </el-timeline>
            <el-empty description="No data found" v-if="total_rows == 0 && !isLoadingHistory" :image="emptyStateImage"></el-empty>
          </div>
        </div>
      </div>
      <b-modal size="xl" v-model="showModalChooseUser" title="Choose User" hide-footer>
        <div class="flex flex-row mb-2 pr-2 float-right">
          <b-input style="width: 200px" size="sm" type="text" v-model="app_user.search_keyword" placeholder="Input search keyword" class="mr-2"/>
          <el-select size="small" v-model="app_user.search_by" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in app_user.search_options" :key="index" :value="item.value" :label="item.text"/>
          </el-select>
          <el-button type="primary" @click="searchUserHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
          <el-button type="primary" @click="doShowModalImportUser" size="small"><font-awesome-icon icon="user-plus"/> Import User</el-button>
        </div>
        <el-table
          @selection-change="handleSelectionUser"
          v-loading="loading.user"
          :data="app_user.list"
          class="table table-vcenter"
          @row-click="handlerClickRowUser"
          ref="userTable">
          <el-table-column
            type="selection"
            width="55"/>
            <el-table-column
              label="Name"
              prop="full_name"
              />
            <el-table-column
              label="Company"
              prop="company_name"
              width="150"
              />
            <el-table-column
              label="Email"
              prop="email"
              width="240"/>
            <el-table-column
              label="Phone Number"
              prop="phone_number"
              width="150"/>
            <el-table-column
              label="Join Date"
              width="160">
              <template slot-scope="scope">{{ scope.row.created_at_str }}</template>
            </el-table-column>
            <el-table-column
              label="Last Login"
              width="160">
              <template slot-scope="scope">{{ scope.row.login_at_str }}</template>
            </el-table-column>
            <el-table-column
              label="Status"
              width="100">
              <template slot-scope="scope">
                <span class="tag" :class="scope.row.status_color">{{ scope.row.status_str }}</span>
              </template>
            </el-table-column>
        </el-table>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ app_user.total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="userPageChangeHandler"
              :per-page="app_user.per_page"
              :limit="5"
              :total-rows="app_user.total_rows"
              v-model="app_user.page"
            />
          </div>
        </div>
        <div class="d-flex flex-row float-right">
          <el-button @click="confirmSelectUser" :loading="loading.give_voucher" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
          <el-button @click="closeModalShowUser" size="small" class="mr-2">Cancel</el-button>
          <!-- <b-button class="mr-2" size="sm" @click="closeModalGiveVoucher" type="reset" variant="secondary">Cancel</b-button> -->
        </div>
      </b-modal>
      <b-modal size="lg" v-model="showModalImportUser" title="Import Private User" hide-footer>
        <b-form @submit.prevent="confirmImportUser" @reset="closeModalImportUser">
          <b-form-group label="File">
            <b-form-file
              v-model="v$.formImportPrivate.file.$model"
              :state="Boolean(v$.formImportPrivate.file.$model)"
              class="border-1 p-2"
              accept=".csv, .xlsx, .xls"
              ref="fileInput"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
            <b-form-invalid-feedback :state="false">
              <div class="w-full flex flex-row justify-content-between">
                <span>*only .csv, .xlsx or .xls file allowed</span>
                <a :href="require('../../../assets/samples/Sample-Import-User-Promo.xlsx')" download><font-awesome-icon icon="download"/> Download example file</a>
              </div>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="!v$.formImportPrivate.file.$invalid" v-if="v$.formImportPrivate.file.$errors[0]">
              <span> {{ v$.formImportPrivate.file.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="confirmImportUser" :loading="loading.import_user" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="closeModalImportUser" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { uniqBy, isEmpty } from 'lodash';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { quillEditor } from 'vue-quill-editor';
// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {
  required, maxValue, minValue, requiredIf,
} from '@vuelidate/validators';
import {
  // GET_PROMOS, CREATE_PROMO, UPDATE_PROMO, DELETE_PROMO, APPROVE_REJECT_PROMO, ENABLE_DISABLE_PROMO,
  GET_DETAIL_PROMO, CREATE_PROMO, UPDATE_PROMO, GET_HISTORY_PROMO, APPROVE_REJECT_PROMO,
} from '@/store/modules/promo';
import {
  GET_APPUSERS, GET_APPUSERS_BY_COMPANY, IMPORT_PRIVATE_USER_PROMO, IMPORT_PRIVATE_USER
} from '@/store/modules/app_user';
import {
  PAYMENT_METHOD_LIST,
} from '@/store/modules/transactions';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import base64File from '@/library/base64File';
import imageCompress from '@/library/vue-image-compression';
import { GET_COMPANIES } from '../../../store/modules/companies';
import { VOUCHER_LIST_UV, GET_VOUCHERS } from '@/store/modules/vouchers';

export default {
  name: 'CreatePromo',
  components: {
    quillEditor,
  },
  metaInfo: {
    title: 'Promo',
  },
  computed: {
    checkboxStatus() {
      return this.form.is_first_transaction ? 'True' : 'False';
    },
    title() {
      if(this.$route.params.action === 'add'){
        return 'New Promo'
      }else if(this.$route.params.action === 'edit'){
        return 'Edit Promo'
      }else if(this.$route.params.action === 'show'){
        return 'Show Promo'
      }
      // return this.$route.params.action === 'add' ? 'New Promo' : 'Edit Promo';
    },
    showingFrom() {
      return this.app_user.total_rows ? ((this.app_user.page - 1) * this.app_user.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.app_user.total_rows) {
        if (this.app_user.list.length < this.app_user.per_page) {
          return this.app_user.total_rows;
        }
        return this.app_user.page * this.app_user.per_page;
      }
      return 0;
    },
    modalTitle() {
      if(this.modalType === 'add'){
        return 'Create New Promo'
      }else if(this.modalType === 'edit'){
        return 'Edit Promo'
      }else if(this.modalType === 'show'){
        return 'Show Promo'
      }
      // return this.modalType === 'add' ? 'Create New Promo' : 'Edit Promo';
    },
    currentRole() {
      return this.$store.getters.current_role;
    },

    isPaymentMethodSelected() {
      return (
        this.form.promo_payment_method &&
        this.form.promo_payment_method.length > 0
      );
    },
    // del
    voucherNames() {
      const voucherNames = {};
      this.list_voucher.forEach((voucher) => {
        voucherNames[voucher.voucher_id] = voucher.voucher_name;
      });
      return voucherNames;
    },
  },

  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },

  validations() {
    return {
      form: {
        name: {
          required,
        },
        voucher_code: {
          required,
        },
        type: {
          required,
        },
        discount_type: {
          required,
        },
        discount_amount: {
          required,
          maxValue: maxValue(this.form.discount_type === 'percent' ? 100 : 1000000),
        },
        start_date: {
          required,
          minValue: (val) => moment(val).isSameOrBefore(this.form.end_date),
        },
        voucher_id: { required },
        end_date: {
          // required,
          // minValue: (val) => moment(val).isSameOrAfter(this.form.start_date),
          //del
          // validateEndAfterStart: (value) => {
          // return moment(value).isSameOrAfter(this.form.start_date);
          validateEndAfterStart: (value) => {
            // Only apply the rule if the end_date is provided
            if (this.form.end_date) {
              return moment(value).isSameOrAfter(this.form.start_date);
            }
            return true; // Allow submission if end_date is not provided
          },
        },
        register_start_date: {
          // required: requiredIf(this.form.limitation_by_register_date),
          // minValue: (val) => moment(val).isSameOrBefore(this.form.register_end_date),
          required: requiredIf(this.form.limitation_by_register_date),
          minValue: (val) => {
            const isValid = moment(val).isSameOrAfter(this.form.register_end_date);
            return isValid || 'Start date must be after or equal to end date';
          },
        },
        register_end_date: {
          // required: requiredIf(this.form.limitation_by_register_date),
          // minValue: (val) => moment(val).isSameOrAfter(this.form.register_start_date),
          required: requiredIf(this.form.limitation_by_register_date),
          minValue: (val) => {
            const isValid = moment(val).isSameOrAfter(this.form.register_start_date);
            return isValid || 'End date must be after or equal to start date';
          },
        },
        max_use: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(100),
        },
        minimum_amount_transaction: { required },
        discount_amount_max: { required: requiredIf(this.form.discount_type === 'percent') },
        reject_reason: { required: requiredIf(this.$route.params.action === 'review') },
        promo_payment_method: { required },
      },
      formImportPrivate: {
        // company_id: { required },
        file: { required },
      },
      formRemovePrivate: {
        company_id: { required },
        file: { required },
      },
    };
  },
  data() {
    return {
      companies: {
        rows: [],
        count: 0,
      },
      timeoutSearchCompany: null,
      isLoadingVoucher: false,
      list_voucher: [],
       voucher: {
        search_by: 'voucher_name',
        search_keyword: '',
      },
      moment,
      loading: {
        create_promo: false,
        user: false,
        company: false,
        list: false,
        import_user: false,
        import_company: false,
        remove_user: false,
        remove_company: false,
      },
      showModalChooseUser: false,
      showModalCreate: false,
      showModalChooseCompany: false,
      emptyStateImage,
      merchantList: [],
      merchant_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      pageList: -1,
      list: [],
      isLoading: true,
      isLoadingHistory: false,
      loader: null,
      search_keyword: '',
      search_by: '',
      search_merchant_keyword: '',
      modalType: 'add',
      last_reject_reason: '',
      form: {
        name: '',
        type: 'voucher_code',
        discount_type: 'percent',
        voucher_code: '',
        discount_amount: '',
        max_use: '',
        discount_amount_max: '',
        minimum_amount_transaction: '',
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(7, 'days').format('YYYY-MM-DD'),
        promo_description: '',
        promo_banner: '',
        promo_image_url: '',
        promo_image_homepage: '',
        is_exclusive_promo: false,
        is_mybenefit: false,
        promo_product_type: [],
        // promo_payment_method: [],
        promo_payment_method: null, 
        promo_platform: ['ios', 'android'],
        limitation_by_register_date: false,
        register_start_date: '',
        register_end_date: '',
        promo_quote: null,
        promo_quote_day: null,
        promo_company_id: [],
        is_cashback: false,
        voucher_name: '',
        voucher_id: [],
        company_id: [],
        is_first_transaction: false
      },
      timeoutSearchVoucherSku: null,
      loading: {
        create_voucher: false,
        detail: false,
        upload_voucher: false,
        voucher_sku: false,
      },
      editorOption: {
        placeholder: 'Please input promo description',
      },
      promoTypeOptions: [
        {
          value: 'voucher_code',
          text: 'Voucher Code',
        },
        {
          value: 'admin_fee',
          text: 'Admin Fee',
        },
      ],
      discountTypeOptions: [
        {
          value: 'percent',
          text: 'Percentage',
        },
        {
          value: 'fixed',
          text: 'Fixed Amount',
        },
      ],
      deviceTypeOptions: [
        {
          value: 'ios',
          text: 'iOS',
        },
        {
          value: 'android',
          text: 'Android',
        },
      ],
      datePickerOptions: {
        Date: (date) => moment(date).isBefore(moment(), 'days'),
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              const start = moment().format();
              picker.$emit('pick', start);
            },
          },
          {
            text: 'Yesterday',
            onClick(picker) {
              const start = moment().subtract(1, 'days').format();
              picker.$emit('pick', start);
            },
          },
          {
            text: 'Next week',
            onClick(picker) {
              const start = moment().add(1, 'week').format();
              picker.$emit('pick', start);
            },
          },
          {
            text: 'Next month',
            onClick(picker) {
              const start = moment().add(1, 'month').format();
              picker.$emit('pick', start);
            },
          },
        ],
      },
      selected_file: {
        promo_banner: null,
        promo_banner_base64: '',
        promo_image_url: null,
        promo_image_url_base64: '',
        promo_image_homepage: null,
        promo_image_homepage_base64: '',
      },
      review_status_filter: '',
      modal_action: 'add',
      selected_promo: {},
      productTypeOptions: [
        {
          value: 'voucher',
          label: 'Voucher',
        },
        {
          value: 'prabayar pln',
          label: 'PLN',
        },
        {
          value: 'prepaid pulsa',
          label: 'Pulsa',
        },
        {
          value: 'ISAT Matrix',
          label: 'Matrix Pascabayar',
        },
        {
          value: 'TSEL Kartu Halo',
          label: 'HALO Pascabayar',
        },
        {
          value: 'XCEL Xplor',
          label: 'XL Xplor',
        },
        {
          value: 'Telkom',
          label: 'Telkom',
        },
        {
          value: 'pascabayar pln',
          label: 'PLN Pascabayar',
        },
        {
          value: 'ewallet',
          label: 'E-Wallet (OVO, Gopay, etc)',
        },
        {
          value: 'paket data',
          label: 'Paket Data',
        },
        {
          value: 'lms',
          label: 'Academy',
        },
        {
          value: 'insurance',
          label: 'Insurance',
        },
        {
          value: 'internet',
          label: 'Internet',
        },
        {
          value: 'tv',
          label: 'Tv',
        },
        {
          value: 'pdam',
          label: 'PDAM',
        },
      ],
      app_user: {
        list: [],
        total_rows: 0,
        page: 1,
        per_page: 20,
        search_keyword: '',
        search_by: 'full_name',
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
          { value: 'phone_number', text: 'Phone Number' },
        ],
      },
      temp_checked_user: [],
      checked_user: [],
      payment_method_list: [],
      company_list: [],
      company_search_timeout: null,
      company_search_filter: {
        search_by: 'company_name',
        search_keyword: '',
      },
      promo_id: null,
      showModalImportUser: false,
      formImportPrivate: {
        company_id: '',
        file: null,
      },
      formRemovePrivate: {
        company_id: '',
        file: null,
      },
      segmentation_options: [
        {
          value: 'private',
          label: 'Private User',
        },
        {
          value: 'public',
          label: 'Public User',
        },
      ],
    };
  },
  async mounted() {
    const loader = this.$loading.show();
    this.isLoading = true;
    await this.getPaymentMethodList();
    if (['edit', 'review', 'show'].includes(this.$route.params.action)) await this.getDetail();
    if (this.$route.query.dup_source) {
      this.promo_id = this.$route.query.dup_source;
      await this.getDetail();
    };
    this.getHistoryPromo();
    this.getCompanies();
    this.isLoading = false;
    loader.hide();
    // this.getVoucherUv();
    this.getListVoucher();
    await this.loadCompanies();
  },
  methods: {
    async onChangeFile(event, target) {
      if (this.selected_file[target]) {
        const compressed = await imageCompress(this.selected_file[target]);
        this.selected_file[`${target}_base64`] = await base64File(compressed);
        try {
          this.$refs[`current_${target}`].src = this.selected_file[`${target}_base64`];
          this.form[`current_${target}`] = this.selected_file[`${target}_base64`];
          this.form[target] = this.form[`current_${target}`];
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getDetail() {
      const response = await this.$store.dispatch(GET_DETAIL_PROMO, this.$route.query.dup_source ? this.$route.query.dup_source : this.$route.params.id);
      const item = response.data.promo;
      this.selected_promo = item;
      this.form = {
        id: item._id,
        name: item.promo_name,
        type: item.promo_type,
        discount_type: item.config.voucher_type_discount,
        voucher_code: item.config.voucher_code,
        discount_amount: item.config.voucher_discount,
        discount_amount_max: parseInt(item.config.discount_amount_max, 0),
        minimum_amount_transaction: item.config.minimum_amount_transaction,
        max_use: item.config.max_use,
        promo_banner: item.promo_banner,
        promo_image_url: item.promo_image_url,
        promo_image_homepage: item.promo_image_homepage,
        promo_description: item.promo_description,
        start_date: moment(item.promo_start_at).format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(item.promo_end_at).format('YYYY-MM-DD HH:mm:ss'),
        is_exclusive_promo: item.is_exclusive_promo || false,
        is_mybenefit: item.is_mybenefit || false,
        review_status: item.review_status,
        reject_reason: item.reject_reason || '',
        promo_product_type: item.promo_product_type || [],
        promo_payment_method: item.promo_payment_method ? item.promo_payment_method : this.payment_method_list.map((v) => v.id),
        promo_platform: item.promo_platform || ['ios', 'android'],
        owned_by: item.config.owned_by,
        limitation_by_register_date: !isEmpty(item.promo_register_at),
        is_cashback: item.is_cashback,
        voucher_id: item.voucher_id,
        register_start_date: !isEmpty(item.promo_register_at) ? item.promo_register_at[0] : null,
        register_end_date: !isEmpty(item.promo_register_at) ? item.promo_register_at[1] : null,
        promo_quote: item.promo_quote,
        promo_quote_day: item.promo_quote_day,
        promo_company_id: item.promo_company_id,
        is_first_transaction: item.is_first_transaction || false,
      };
      if (item.promo_type === 'admin_fee') {
        this.form.discount_type = item.config.adminfee_type_discount;
        this.form.discount_amount = item.config.adminfee_discount;
      }
      this.form.current_promo_banner = item.promo_banner;
      this.form.current_promo_image_url = item.promo_image_url;
      this.form.current_promo_image_homepage = item.promo_image_homepage;
      this.v$.form.$touch();
      if (this.$route.params.action === 'review') {
        this.last_reject_reason = this.form.reject_reason;
        this.form.reject_reason = '';
      }
    },
    async getHistoryPromo() {
      this.isLoadingHistory = true;
      await this.$store.dispatch(GET_HISTORY_PROMO, this.$route.query.dup_source ? this.$route.query.dup_source : this.$route.params.id);
      const { count, rows } = this.$store.getters.promo_histories;
      this.total_rows = count;
      this.list = count > 0 ? rows : [];
      this.isLoadingHistory = false;
    },
    closeModalCreatePromo() {
      this.$router.push({
        path: '/promo',
      });
    },
    confirmCreatePromo() {
      if (this.$route.params.action === 'edit') {
        this.doUpdatePromo();
        return;
      }
      this.$confirm('You will create a new promo. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.doCreatePromo();
          }
          done();
        },
      }).catch(() => {});
    },
    async doUpdatePromo() {
      this.form.minimum_amount_transaction = parseInt(this.form.minimum_amount_transaction, 0);
      this.form.discount_amount_max = parseInt(this.form.discount_amount_max, 0);
      this.form.max_use = parseInt(this.form.max_use, 0);
      this.form.discount_amount = parseFloat(this.form.discount_amount);
      if (this.checked_user.length > 0){
        this.form.owned_by = this.checked_user.map((v) => v.owned_by);
      }else if (this.checked_user.length == 0){
        this.form.owned_by = null;
      }
      this.$confirm('You will update this promo. Make sure you have input data correctly. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            this.loading.create_promo = true;
            await this.$store.dispatch(UPDATE_PROMO, this.form)
              .then(() => {
                this.$message({
                  title: 'Success',
                  type: 'success',
                  message: 'Promo updated',
                });
                this.showModalCreate = false;
              }).catch((err) => {
                popupErrorMessages(err?.response?.data);
                this.loading.create_promo = false;
              });
            this.loading.create_promo = false;
            this.getHistoryPromo();
            this.$router.push({
              name: 'Promo',
            });
            return;
          }
          done();
        },
      }).catch(() => {});
    },
    // async doCreatePromo() {
    //   this.loading.create_promo = true;
    //   if (this.checked_user.length > 0) this.form.owned_by = this.checked_user.map((v) => v.owned_by);
    //   await this.$store.dispatch(CREATE_PROMO, this.form)
    //     .then(() => {
    //       this.$message({
    //         title: 'Success',
    //         type: 'success',
    //         message: 'Promo created',
    //       });
    //       this.showModalCreate = false;
    //       this.$router.push({
    //         name: 'Promo',
    //       });
    //     }).catch((err) => {
    //       popupErrorMessages(err?.response?.data);
    //     });
    //   this.loading.create_promo = false;
    // },
    // del
    async doCreatePromo() {
      this.loading.create_promo = true;
      // Check if the selected product type is 'voucher' and Specific SKU is not selected
      if (this.form.promo_product_type.includes('voucher') && this.form.voucher_id.length === 0) {
        // If not selected, automatically select all options in Specific SKU
        const allVoucherIds = this.list_voucher.map(voucher => voucher.voucher_id);
        this.form.voucher_id = allVoucherIds;
      }
      if (this.checked_user.length > 0) {
        this.form.owned_by = this.checked_user.map((v) => v.owned_by);
      }
      try {
        await this.$store.dispatch(CREATE_PROMO, this.form);
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Promo created',
        });
        this.showModalCreate = false;
        this.$router.push({
          name: 'Promo',
        });
      } catch (err) {
        popupErrorMessages(err?.response?.data);
      } finally {
        this.loading.create_promo = false;
      }
    },
    createNewPromo() {},
    closeModalCreate() {},
    confirmApproveReject(confirm_action) {
      if (confirm_action === 'reject') {
        console.log('this.form.limitation_by_register_date', this.form.limitation_by_register_date);
        this.v$.form.$touch();
        if (this.v$.form.$error) return;
      }

      let msg = `You will approve this promo (${this.selected_promo.promo_name}). Continue?`;
      if (confirm_action === 'reject') {
        msg = `You will reject this promo (${this.selected_promo.promo_name}). Please input a reason why you reject this promotion.`;
      }
      this.$confirm(msg, 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(APPROVE_REJECT_PROMO, {
              id: this.selected_promo._id,
              data: {
                review_status: confirm_action === 'reject' ? 'rejected' : 'approved',
                reject_reason: confirm_action === 'reject' ? this.form.reject_reason : null,
              },
            }).catch(() => {});
            this.$message({
              title: 'Success',
              type: 'success',
              message: 'Review success',
            });
            instance.confirmButtonLoading = false;
            this.form.review_status = confirm_action === 'reject' ? 'rejected' : 'approved';
            this.getHistoryPromo();
          }
          done();
          this.closeModalCreatePromo();
        },
      }).catch(() => {
        this.doShowModalEdit(this.selected_promo, 'review');
      });
    },
    chooseUserHandler() {
      this.showModalChooseUser = true;
      this.userPageChangeHandler(1);
      // console.log();
    },
    searchUserHandler() {
      this.userPageChangeHandler(1);
    },
    userPageChangeHandler(page) {
      this.app_user.page = page;
      this.checked_user.push(...this.temp_checked_user);
      this.checked_user = uniqBy(this.checked_user, 'owned_by');
      if (this.$refs.userTable) this.$refs.userTable.clearSelection();
      this.loading.user = true;
      setTimeout(() => this.getListUser(), 500);
      
    },
    async getListUser() {
      this.loading.user = true;
      const promo_company_id_str = this.form.promo_company_id.join(",");
      // console.log("======>", promo_company_id_str);

      // await this.$store.dispatch(GET_APPUSERS, {
      await this.$store.dispatch(GET_APPUSERS_BY_COMPANY, {
        page: this.app_user.page,
        per_page: this.app_user.per_page,
        search_by: this.app_user.search_by,
        search_keyword: this.app_user.search_keyword,
        company_id: this.form.is_mybenefit ? promo_company_id_str : ''
        // company_id: 3
      }).catch(() => {});
      const { rows, count } = this.$store.getters.app_users;
      this.app_user.total_rows = count || 0;
      this.app_user.list = rows;
      this.loading.user = false;
      setTimeout(() => this.refreshSelectedUser(), 200);
    },
    refreshSelectedUser() {
      if (this.$refs.userTable) {
        try {
          this.$refs.userTable.clearSelection();
          let temp = [];
          this.checked_user.forEach((row) => {
            const obj = this.app_user.list.find((v) => v.owned_by === row.owned_by);
            if (obj) temp.push(obj);
          });
          if (this.form.owned_by && this.form.owned_by.length > 0) {
            this.form.owned_by.forEach((id) => {
              const obj = this.app_user.list.find((v) => v.owned_by === id);
              if (obj) temp.push(obj);
            });
          }
          temp = uniqBy(temp, 'owned_by');
          this.toggleSelection(temp);
        } catch (error) {
          console.log(new Date().toString(), 'error', error);
        }
      }
    },
    toggleSelection(rows) {
      if (rows.length > 0) {
        rows.forEach((row) => {
          try {
            this.$refs.userTable.toggleRowSelection(row);
          } catch (error) {
            console.log('error', error);
          }
        });
      }
    },
    handlerClickRowUser(item) {
      this.$refs.userTable.toggleRowSelection(item);
    },
    handleSelectionUser(val) {
      this.temp_checked_user = val;
    },
    confirmSelectUser() {
      this.checked_user.push(...this.temp_checked_user);
      this.checked_user = uniqBy(this.checked_user, 'owned_by');
      this.showModalChooseUser = false;
    },
    closeModalShowUser() {
      this.showModalChooseUser = false;
      this.temp_checked_user = [];
    },
    async getPaymentMethodList() {
      await this.$store.dispatch(PAYMENT_METHOD_LIST);
      this.payment_method_list = this.$store.getters.payment_method_list;
    },
    changeHandlerPaymentMethod(value) {
      if (value.includes('all')) {
        this.form.promo_payment_method = this.payment_method_list.map((v) => {
          if(v.id != 64) return v.id
        });
      }
    },
    /* eslint-disable prefer-object-spread */
    async getCompanies() {
      this.loading.company = true;
      await this.$store.dispatch(GET_COMPANIES, this.company_search_filter).catch(() => {});
      this.loading.company = false;
      const { rows } = this.$store.getters.companies;
      if (this.form.promo_company_id.length) {
        const temp = Object.assign([], this.company_list);
        // console.log(temp);
        const selected_company = temp.filter((v) => this.form.promo_company_id.includes(v.company_id));
        this.company_list = uniqBy([...selected_company, ...rows], 'company_id');
      } else {
        this.company_list = rows;
      }
    },
    handlerSearchCompany(value) {
      // console.log(value);
      clearTimeout(this.company_search_timeout);
      this.company_search_timeout = setTimeout(() => {
        this.company_search_filter.search_keyword = value;
        this.getCompanies();
      }, 1000);
    },
    doShowModalImportUser() {
      this.showModalImportUser = true;
      // this.formImportPrivate.company_id = this.$route.params.id;
      // this.v$.formImportPrivate.$touch();
    },
    async confirmImportUser() {
      this.v$.formImportPrivate.$touch();
      if (this.v$.formImportPrivate.$error) return;
      const data = new FormData();
      data.append('file', this.formImportPrivate.file);
      this.loading.import_user = true;
      await this.$store.dispatch(IMPORT_PRIVATE_USER, {
        id: this.formImportPrivate.company_id,
        data,
      }).then(() => {
        this.formImportPrivate = {
          company_id: '',
          file: null,
        };
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Import private user completed.',
        });
        this.closeModalImportUser();
        this.loadCompanyPrivateUser();
      }).catch((err) => {
        popupErrorMessages(err.response.data);
      });
      this.loading.import_user = false;
    },
    async confirmRemoveUser() {
      this.v$.formRemovePrivate.$touch();
      if (this.v$.formRemovePrivate.$error) return;
      const data = new FormData();
      data.append('file', this.formRemovePrivate.file);
      data.append('company_id', this.formRemovePrivate.company_id);
      this.loading.remove_user = true;
      await this.$store.dispatch(SINGLE_REMOVE_PRIVATE_USERS, {
        id: this.formRemovePrivate.company_id,
        data,
      }).then(() => {
        this.formRemovePrivate = {
          company_id: '',
          file: null,
        };
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Remove private user completed.',
        });
        this.closeModalRemoveUser();
        this.loadCompanyPrivateUser();
      }).catch((err) => {
        popupErrorMessages(err.response.data);
      });
      this.loading.remove_user = false;
    },
    closeModalImportUser() {
      this.showModalImportUser = false;
      this.loading.import_user = false;
    },
    closeModalRemoveUser() {
      this.showModalRemoveUser = false;
      this.loading.remove_user = false;
    },
    // async getVoucherUv(search_keyword = '') {
    //   try {
    //     this.loading.voucher_sku = true;
    //     const { data } = await this.$store.dispatch(VOUCHER_LIST_UV, {
    //       search_by: 'sku_name',
    //       search_keyword,
    //     });
    //     this.loading.voucher_sku = false;
    //     this.uv_voucher = data.rows;
    //     // console.log('uv_voucher:', this.uv_voucher); 
    //   } catch (error) {
    //     console.error('Error fetching uv_voucher:', error);
    //   }
    // },
    handlerSearchVoucherSku(value) {
      clearTimeout(this.timeoutSearchVoucherSku);
      this.timeoutSearchVoucherSku = setTimeout(() => {
        this.getVoucherUv(value);
      }, 1000);
    },
    async getListVoucher() {
      this.isLoadingVoucher = true;
      await this.$store.dispatch(GET_VOUCHERS, {
        page: this.pageList,
        search_by: this.voucher.search_by,
        search_keyword: this.voucher.search_keyword,
      }).catch(() => {});
      const { rows } = this.$store.getters.vouchers;
      this.list_voucher = rows.filter(item => item.voucher_status === 1);
      this.isLoadingVoucher = false;
    },

    handlerVoucherChange(value) {
      if (value.includes('')) {
        const allVoucherIds = this.list_voucher.map(voucher => voucher.voucher_id);
        this.form.voucher_id = allVoucherIds;
      } else {
        this.form.voucher_id = value.filter(v => v !== '');
      }
    },
    searchVoucher(query) {
      this.timeout = setTimeout(() => {
        this.voucher.search_keyword = query;
        this.getListVoucher();
      }, 1000);
    },
    async loadCompanies({ search_keyword = '' } = {}) {
      this.loading.company = true;
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.loading.company = false;
      this.companies = this.$store.getters.companies;
    },
    searchCompany(value) {
        clearTimeout(this.timeoutSearchCompany);
        this.timeoutSearchCompany = setTimeout(() => {
          this.loadCompanies({
            search_keyword: value,
          });
        }, 1000);
    },
  },
};
</script>
<style>
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
</style>
